import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import {routes} from './routes'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

//拦截未登录情况
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (!sessionStorage.getItem("banzhuLoginInfo")) {
    if (to.path != '/login') {
      return next(`/login?redirect=${to.path}`)
    }
  }
  next()
})

router.afterEach(to => {
  NProgress.done()
})

export default router
