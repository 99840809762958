<script setup>
import { computed, ref, onMounted } from 'vue'
import router from '../../router'
import hamburger from '@/components/Hamburger'
import breadcrumb from '@/components/Breadcrumb'
import { useAppStoreHook } from '@/store/modules/app'

const pureApp = useAppStoreHook()

const toggleSideBar = () => {
  pureApp.toggleSideBar()
}

const logout = () => {
  sessionStorage.removeItem('banzhuLoginInfo')
  router.push('/login')
}

const sidebar = computed(() => {
  return pureApp.getSidebar
})

// 计算高度
const navBarElement = ref(null) // 获取 DOM 元素的引用
onMounted(() => {
  if (navBarElement.value) {
    // 获取 offsetHeight 并存储到 Pinia
    const height = navBarElement.value.offsetHeight
    pureApp.setNavBarOffSetHeight(height)
  }
})
</script>

<template>
  <div ref="navBarElement" class="navbar">
    <hamburger
      :is-active="!sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />

    <el-dropdown
      class="avatar-container right-menu-item hover-effect"
      trigger="click"
    >
      <div class="avatar-wrapper">
        <img class="user-avatar" src="@/assets/logo.png" />
        <el-icon><CaretBottom /></el-icon>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="logout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<style lang="less" scoped>
@import '@/styles/variables.module.less';

.navbar {
  height: 50px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  align-items: center;
  padding-right: 30px;
  box-sizing: border-box;
  position: relative;
  z-index: 99;

  .avatar-container {
    margin-left: auto;

    .avatar-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      .user-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
}
</style>
