<template>
  <template v-if="!item.hidden">
    <template
      v-if="hasOneShowingChild(item.children, item) && !onlyOneChild.children"
    >
      <el-menu-item
        :index="onlyOneChild.path"
        :class="{ 'submenu-title-noDropdown': !isNest }"
      >
        <el-icon>
          <component
            :is="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
          ></component>
        </el-icon>
        <template #title>{{ onlyOneChild.meta.title }}</template>
      </el-menu-item>
    </template>

    <el-sub-menu v-else :index="item.path">
      <template #title>
        <el-icon><component :is="item.meta.icon"></component></el-icon>
        <span>{{ item.meta.title }}</span>
      </template>

      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :isNest="true"
        :item="child"
        :base-path="child.path"
      />
    </el-sub-menu>
  </template>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      onlyOneChild: null
    }
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = parent
        return true
      }

      return false
    }
  }
}
</script>
