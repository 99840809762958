import { store } from "@/store";
import { defineStore } from "pinia";
import Cookies from 'js-cookie'

export const useAppStore = defineStore('bz-admin', {
  state: () => ({ 
    sidebar: {
      opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true
    },
    navBarOffSetHeight: 0,
    searchOffSetHeight:0
  }),
  getters: {
    getSidebar:state => {
      return state.sidebar;
    },
    getNavBarHeight:state => state.navBarOffSetHeight,
    getSearchHeight:state => state.searchOffSetHeight,
  },
  actions: {
    toggleSideBar() {
      this.sidebar.opened = !this.sidebar.opened

      if (this.sidebar.opened) {
        Cookies.set('sidebarStatus', 1)
      } else {
        Cookies.set('sidebarStatus', 0)
      }
    },
    // 更新 navBarOffSetHeight 的方法
    setNavBarOffSetHeight(height) {
      this.navBarOffSetHeight = height;
      
    },
    // 更新 searchOffSetHeight 的方法
    setSearchOffSetHeight(height) {
      // +20 是加内边距18 + 边框2 
      this.searchOffSetHeight = height;
    },
  },
})

export function useAppStoreHook() {
  return useAppStore(store);
}