<script setup>
import { ref, watch, onMounted, toRaw } from "vue";
import { useRoute, useRouter } from "vue-router";

const levelList = ref([]);
const router = useRouter();
const route = useRoute();


const getBreadcrumb = () => {
  let matched = route.matched.filter(item => item.meta && item.meta.title)
  const first = matched[0]

  levelList.value = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
};

const handleLink = (item) => {
  const { redirect, path } = item;
  if (redirect) {
    router.push(redirect);
  } else {
    router.push(path);
  }
};

onMounted(() => {
  getBreadcrumb();
});

watch(
  () => route.path,
  () => {
    getBreadcrumb();
  },
  {
    deep: true
  }
);
</script>

<template>
  <div>
    <el-breadcrumb  class="app-breadcrumb" separator="/">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item
          v-for="item in levelList"
          :key="item.path"
        >
          <a @click.prevent="handleLink(item)">
            {{ item.meta.title }}
          </a>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
  </div>
</template>


<style lang="less" scoped>
.breadcrumb-enter-active {
  transition: all 0.4s;
}

.breadcrumb-leave-active {
  position: absolute;
  transition: all 0.2s;
}

.breadcrumb-enter-from,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}
</style>